<template>
  <am-modal
    :title="`Assign ${role.name} Permissions`"
    :show="permissionsModalOpened"
    size="lg"
    @onClose="closePermissionModal()"
  >
    <b-row class="align-items-start">
      <div
        v-for="(resource, index) in Object.keys(permissions)"
        :key="index"
        class="col-4"
      >
        <b-form-checkbox
          @input="(e)=>handleAllChecked(e,resource)"
        >
          <strong>{{ resource.toUpperCase() }}</strong>
        </b-form-checkbox>

        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedPermissions"
            :options="permissions[resource]"
            value-field="id"
            text-field="name"
            inline
          />
        </b-form-group>
      </div>
    </b-row>
    <template slot="modal-actions">
      <b-row class="float-right">
        <am-button
          variant="flat-danger"
          icon="XIcon"
          class="mr-1"
          label="Cancel"
          @click="closePermissionModal()"
        />
        <am-button
          label="Proceed"
          :disabled="selectedPermissions.length<1"
          :loading="permissionsLoading"
          loading-text="Loading..."
          @click="assignPermissions()"
        />
      </b-row>
    </template>
  </am-modal>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import {
  BRow, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import showToast from '@/farmbusiness/mixins/showToast'

export default {
  name: 'Permissions',
  components: {
    AmModal, AmButton, BRow, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    role: { type: Object, default: () => {} },
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      permissionsModalOpened: false,
      permissionsLoading: false,
      selectedPermissions: [],
    }
  },

  computed: {
    userPermissions() {
      return this.$store.getters['accountUsers/permissions']
    },
    permissions() {
      const permissions = {}
      const formatted = this.userPermissions
        .map(p => ({ id: p.id, resource: p.resource, name: this.formatPermissionName(p.name) }))
      formatted.forEach(p => {
        permissions[p.resource] = permissions[p.resource] || []
        permissions[p.resource].push({ id: p.id, name: p.name })
      })
      return permissions
    },
  },
  watch: {
    role(role) {
      if (role.permissions && role.permissions.length > 0) {
        this.selectedPermissions = role.permissions.map(p => p.id)
      } else {
        this.selectedPermissions = []
      }
    },
    modalOpened(opened) {
      this.permissionsModalOpened = opened
    },
  },
  methods: {
    formatPermissionName(name) {
      return name.replaceAll('-', ' ').trim().toUpperCase()
    },
    closePermissionModal() {
      this.permissionsModalOpened = false
      this.$emit('modalClosed')
    },
    assignPermissions() {
      this.permissionsLoading = true
      this.$store.dispatch('accountUsers/assignPermissions', { permissions: this.selectedPermissions, role_id: this.role.id }).then(res => {
        this.showSuccess('Permissions assigned successfully')
      }).catch(err => {
        this.showError('Failed to assign permissions')
      }).finally(() => {
        this.permissionsLoading = false
      })
    },
    handleAllChecked(checked, resource) {
      const permissions = this.permissions[resource].map(p => p.id)
      if (checked) {
        this.selectedPermissions = [...this.selectedPermissions, ...permissions]
      } else {
        this.selectedPermissions = this.selectedPermissions.filter(p => !permissions.includes(p))
      }
    },
  },
}
</script>

<style scoped>

</style>
