<template>
  <!--    User Form-->
  <validation-observer
    ref="userForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateUserMode?'Update':'Create'} User`"
      :show="userModalOpened"
      :show-overlay="deletingUser"
      size="md"
      @onClose="closeUserModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedUserId"
            placeholder="All Users"
            :options="users"
            label-name="name"
            value-name="id"
            name="users"
            @input="populateUserForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            icon="TrashIcon"
            name="delete_user"
          >
            <b-dropdown-item
              id="cd_user"
              @click="deleteUser()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="user_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetUserForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-input
        v-model="user.first_name"
        name="first_name"
        placeholder="Enter user first name"
        rules="required"
        label="First Name"
      />
      <am-input
        v-model="user.last_name"
        name="last_name"
        placeholder="Enter user last name"
        rules="required"
        label="Last Name"
      />
      <am-input
        v-model="user.email"
        name="email"
        placeholder="Enter user email"
        rules="required|email"
        label="Email"
      />
      <am-input
        v-model="user.phone"
        name="phone"
        placeholder="Enter user phone"
        label="Phone"
        rules="required"
      />
      <div class="mt-2">
        <am-select
          v-model="user.role_id"
          name="role_id"
          value-name="id"
          label-name="name"
          placeholder="Select Role"
          label="Role"
          :options="roles"
          rules="required"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeUserModal()"
          />
          <am-button
            name="save_user"
            :label="updateUserMode?'Update':'Create'"
            :disabled="invalid"
            :loading="userLoading"
            loading-text="Loading..."
            @click="updateUserMode?updateUser():createUser()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'

const defaultUser = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  role_id: null,
}
export default {
  name: 'UserSetup',
  components: {
    ErrorDisplay,
    AmDropdown,
    AmSelect,
    AmButton,
    AmInput,
    AmModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      userModalOpened: false,
      updateUserMode: false,
      userLoading: false,
      deletingUser: false,
      selectedUserId: null,
      user: { ...defaultUser },
    }
  },
  computed: {
    selectedUser() {
      const user = this.users.find(ter => ter.id === this.selectedUserId)
      if (!user) {
        return null
      }
      return {
        ...user,
      }
    },
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
    users() {
      return this.$store.getters['accountUsers/users']
        .map(u => ({
          ...u,
          name: `${u.first_name} ${u.last_name}`,
        }))
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
  },
  watch: {
    modalOpened(opened) {
      this.userModalOpened = opened
    },
    id(id) {
      this.selectedUserId = id
    },
  },
  methods: {
    resetUserForm() {
      this.user = { ...defaultUser }
      this.selectedUserId = null
      this.updateUserMode = false
      this.$refs.userForm.reset()
    },
    populateUserForm(user) {
      if (this.selectedUser) {
        this.updateUserMode = true
        this.user = {
          id: this.selectedUser.id,
          first_name: this.selectedUser.first_name,
          last_name: this.selectedUser.last_name,
          email: this.selectedUser.email,
          phone: this.selectedUser.phone,
          role_id: this.roles.find(r => r.name === this.selectedUser.role)?.id || null,
        }
      } else {
        this.resetUserForm()
      }
    },
    closeUserModal() {
      this.userModalOpened = false
      this.$emit('modalClosed')
    },
    createUser() {
      this.error = {}
      this.userLoading = true
      this.$store.dispatch('accountUsers/createUser', this.user).then(res => {
        this.showSuccess('Created user successfully')
        this.resetUserForm()
      })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create user')
        }).finally(() => {
          this.userLoading = false
        })
    },
    updateUser() {
      this.error = {}
      if (!this.user.id) {
        return
      }
      this.userLoading = true
      this.$store.dispatch('accountUsers/updateUser', this.user).then(res => {
        this.showSuccess('Updated user successfully')
      })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update user')
        }).finally(() => {
          this.userLoading = false
        })
    },
    deleteUser() {
      if (!this.selectedUserId) {
        this.showInfo('Please select a user')
        return
      }
      this.deletingUser = true
      this.$store.dispatch('accountUsers/deleteUser', this.selectedUserId).then(() => {
        this.showSuccess('Deleted Successfully')
        this.resetUserForm()
      })
        .catch(() => {
          this.showError('Failed to delete user')
        }).finally(() => {
          this.deletingUser = false
        })
    },
  }
  ,
}
</script>
