var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-users"},[_c('am-list-page',{attrs:{"title":"Roles","columns":_vm.columnsRole,"rows":_vm.roles,"show-search":"","table-loading":_vm.rolesTableLoading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [_c('am-dropdown',{attrs:{"disabled":_vm.isAdmin(row.item.name)}},[_c('am-dropdown-item',{attrs:{"icon":"Edit2Icon","text":"Edit"},on:{"click":function($event){return _vm.showRoleSetupModalForEdit(row.item)}}}),_c('am-dropdown-item',{attrs:{"icon":"LockIcon","text":"Assign Permissions"},on:{"click":function($event){return _vm.showPermissionsModal(row.item)}}})],1)]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{staticClass:"d-flex align-self-center align-items-start mb-2"},[_c('am-button',{attrs:{"name":"manage_roles","icon":"SettingsIcon","label":"Roles","tool-tip-text":"Mange Roles (CRUD)"},on:{"click":function($event){return _vm.showRoleModal()}}})],1)])],2),_c('am-list-page',{attrs:{"title":"Users","columns":_vm.columnsUser,"rows":_vm.users,"table-loading":_vm.tableLoading,"show-search":true,"show-profile-photo":true},scopedSlots:_vm._u([{key:"full_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.item.first_name)+" "+_vm._s(row.item.last_name)+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('am-button',{attrs:{"icon":row.item.active?'UserCheckIcon':'UserXIcon',"icon-only":true,"tool-tip-text":row.item.active?'Active, click to deactivate':'Inactive, click to activate',"variant":row.item.active?'flat-success':'flat-danger',"loading-text":""},on:{"click":function($event){return _vm.activateUser(row.item.id,!row.item.active)}}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('am-dropdown',[_c('am-dropdown-item',{attrs:{"icon":"Edit2Icon","text":"Edit"},on:{"click":function($event){return _vm.showUserSetupModalForEdit(row.item)}}}),_c('am-dropdown-item',{attrs:{"icon":"RefreshCwIcon","text":"Reset Password"},on:{"click":function($event){return _vm.resendUserCredentials(row.item)}}})],1)]}}])},[_c('template',{slot:"right-extra-header-actions"},[_c('div',{staticClass:"d-flex align-self-center align-items-start mb-2"},[_c('am-button',{attrs:{"name":"manage_users","icon":"SettingsIcon","label":"Users","tool-tip-text":"Manage Users (CRUD)"},on:{"click":function($event){return _vm.showUserModal()}}})],1)])],2),_c('user-setup',{attrs:{"id":_vm.selectedUserId,"modal-opened":_vm.userModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('user')}}}),_c('role-setup',{attrs:{"id":_vm.selectedRoleId,"modal-opened":_vm.roleModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('role')}}}),_c('permissions',{attrs:{"role":_vm.selectedRole,"modal-opened":_vm.permissionsModalOpened},on:{"modalClosed":function($event){return _vm.closeModal('permissions')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }