<template>
  <div class="account-users">
    <am-list-page
      title="Roles"
      :columns="columnsRole"
      :rows="roles"
      show-search
      :table-loading="rolesTableLoading"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2">
          <am-button
            name="manage_roles"
            icon="SettingsIcon"
            label="Roles"
            tool-tip-text="Mange Roles (CRUD)"
            class=""
            @click="showRoleModal()"
          />
        </div>
      </template>
      <template #action="{row}">
        <am-dropdown :disabled="isAdmin(row.item.name)">
          <am-dropdown-item
            icon="Edit2Icon"
            text="Edit"
            @click="showRoleSetupModalForEdit(row.item)"
          />
          <am-dropdown-item
            icon="LockIcon"
            text="Assign Permissions"
            @click="showPermissionsModal(row.item)"
          />
        </am-dropdown>
      </template>
    </am-list-page>

    <am-list-page
      title="Users"
      :columns="columnsUser"
      :rows="users"
      :table-loading="tableLoading"
      :show-search="true"
      :show-profile-photo="true"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2">
          <am-button
            name="manage_users"
            icon="SettingsIcon"
            label="Users"
            tool-tip-text="Manage Users (CRUD)"
            class=""
            @click="showUserModal()"
          />
        </div>
      </template>
      <template #full_name="{row}">
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>
      <template #status="{row}">
        <am-button
          :icon="row.item.active?'UserCheckIcon':'UserXIcon'"
          :icon-only="true"
          :tool-tip-text="row.item.active?'Active, click to deactivate':'Inactive, click to activate'"
          :variant="row.item.active?'flat-success':'flat-danger'"
          loading-text=""
          @click="activateUser(row.item.id,!row.item.active)"
        />
      </template>
      <template #action="{row}">
        <am-dropdown>
          <am-dropdown-item
            icon="Edit2Icon"
            text="Edit"
            @click="showUserSetupModalForEdit(row.item)"
          />
          <am-dropdown-item
            icon="RefreshCwIcon"
            text="Reset Password"
            @click="resendUserCredentials(row.item)"
          />
        </am-dropdown>
      </template>
    </am-list-page>

    <user-setup
      :id="selectedUserId"
      :modal-opened="userModalOpened"
      @modalClosed="closeModal('user')"
    />
    <role-setup
      :id="selectedRoleId"
      :modal-opened="roleModalOpened"
      @modalClosed="closeModal('role')"
    />
    <permissions
      :role="selectedRole"
      :modal-opened="permissionsModalOpened"
      @modalClosed="closeModal('permissions')"
    />
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import UserSetup from '@/views/administration/users/UserSetup.vue'
import RoleSetup from '@/views/administration/users/RoleSetup.vue'
import Permissions from '@/views/administration/users/Permissions.vue'
import confirm from '@/farmbusiness/mixins/confirm'
import AmDropdown from '@/farmbusiness/components/AmDropdown'
import AmDropdownItem from '@/farmbusiness/components/AmDropdownItem'

export default {
  name: 'StudentGrading',
  components: {
    AmDropdownItem,
    AmDropdown,
    Permissions,
    RoleSetup,
    UserSetup,
    AmListPage,
    AmButton,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      selectedRole: {},
      roleModalOpened: false,
      userModalOpened: false,
      tableLoading: false,
      rolesTableLoading: false,
      permissionsModalOpened: false,
      selectedRoleId: null,
      selectedUserId: null,
      columnsUser: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Full Name',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'role',
          label: 'Role',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      columnsRole: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: 'Role',
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
    }
  },
  computed: {
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
  },
  mounted() {
    this.fetchRolesUsersPermissions()
  },
  methods: {
    resendUserCredentials(user) {
      const { id } = user
      this.confirmAction(`Password will be reset and sent to ${user.first_name} ${user.last_name}, continue?`).then(res => {
        if (res) {
          this.tableLoading = true
          this.$store.dispatch('accountUsers/resendCredentials', id).then(res => {
            this.showSuccess('Sent successfully')
            this.tableLoading = false
          })
            .catch(res => {
              this.tableLoading = false
              this.showError('Failed to send credentials')
            })
        }
      })
    },
    isAdmin(role) {
      return role === 'Administrator'
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'role':
          this.roleModalOpened = false
          this.selectedRoleId = null
          break
        case 'user':
          this.userModalOpened = false
          this.selectedUserId = null
          break
        case 'permissions':
          this.permissionsModalOpened = false
          break
      }
    },
    showRoleModal() {
      this.roleModalOpened = true
    },
    showUserModal() {
      this.userModalOpened = true
    },
    showPermissionsModal(role) {
      this.selectedRole = role
      this.permissionsModalOpened = true
    },
    activateUser(id, status) {
      this.tableLoading = true
      this.$store.dispatch('accountUsers/activateUser', { status, id })
        .then(res => {
          this.showSuccess(`${status ? 'Activated' : 'Deactivated'} successfully`)
        })
        .catch(res => {
          this.showError(`Failed to ${status ? 'Activate' : 'Deactivate'} user account`)
        }).finally(() => {
          this.tableLoading = false
        })
    },
    fetchRolesUsersPermissions() {
      this.$store.dispatch('accountUsers/getRolesUsersPermissions')
    },
    showUserSetupModalForEdit(item) {
      this.selectedUserId = item.id
      this.userModalOpened = true
    },
    showRoleSetupModalForEdit(item) {
      this.selectedRoleId = item.id
      this.roleModalOpened = true
    },
  },
}
</script>
