<template>
  <!--    Role Form-->
  <validation-observer
    ref="roleForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateRoleMode?'Update':'Create'} Role`"
      :show="roleModalOpened"
      :show-overlay="deletingRole"
      @onClose="closeRoleModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedRoleId"
            name="roles"
            placeholder="All Roles"
            :options="roles"
            label-name="name"
            value-name="id"
            @input="populateRoleForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            name="delete_role"
            icon="TrashIcon"
          >
            <b-dropdown-item
              id="cd_role"
              @click="deleteRole()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="role_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetRoleForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-input
        v-model="role.name"
        name="role_name"
        placeholder="Enter role name"
        label="Role"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeRoleModal()"
          />
          <am-button
            name="save_role"
            :label="updateRoleMode?'Update':'Create'"
            :disabled="invalid"
            :loading="roleLoading"
            loading-text="Loading..."
            @click="updateRoleMode?updateRole():createRole()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import { BRow, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'

export default {
  name: 'RoleSetup',
  components: {
    ErrorDisplay,
    AmDropdown,
    AmSelect,
    AmButton,
    AmInput,
    AmModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      roleModalOpened: false,
      updateRoleMode: false,
      roleLoading: false,
      deletingRole: false,
      selectedRoleId: null,
      role: {
        id: null,
        name: null,
      },
    }
  },
  computed: {
    selectedRole() {
      return this.roles.find(ter => ter.id === this.selectedRoleId)
    },
    roles() {
      return this.$store.getters['accountUsers/roles']
    },
  },
  watch: {
    modalOpened(opened) {
      this.roleModalOpened = opened
    },
    id(id) {
      this.selectedRoleId = id
    },
  },
  methods: {
    // role
    resetRoleForm() {
      this.role = {
        id: null,
        name: null,
      }
      this.selectedRoleId = null
      this.updateRoleMode = false
      this.$refs.roleForm.reset()
    },
    populateRoleForm(role) {
      if (this.selectedRole) {
        this.updateRoleMode = true
        this.role = {
          ...this.selectedRole,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.role.permissions
      } else {
        this.resetRoleForm()
      }
    },
    closeRoleModal() {
      this.roleModalOpened = false
      this.$emit('modalClosed')
    },
    createRole() {
      this.error = {}
      this.roleLoading = true
      this.$store.dispatch('accountUsers/createRole', this.role)
        .then(res => {
          this.showSuccess('Created role successfully')
          this.resetRoleForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create role')
        }).finally(() => {
          this.roleLoading = false
        })
    },
    updateRole() {
      this.error = {}
      if (!this.role.id) {
        return
      }
      this.roleLoading = true
      this.$store.dispatch('accountUsers/updateRole', this.role).then(res => {
        this.showSuccess('Updated role successfully')
      })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update role')
        }).finally(() => {
          this.roleLoading = false
        })
    },
    deleteRole() {
      if (!this.selectedRoleId) {
        this.showInfo('Please select a role')
        return
      }
      this.deletingRole = true
      this.$store.dispatch('accountUsers/deleteRole', this.selectedRoleId).then(() => {
        this.showSuccess('Deleted Successfully')
        this.resetRoleForm()
      })
        .catch(() => {
          this.showError('Failed to delete role')
        }).finally(() => {
          this.deletingRole = false
        })
    },
  },
}
</script>
